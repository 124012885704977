.container {
    width:90%;
    border: 2px solid var(--lapis);
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    height:90%;
}

.header {
    width:100%;
    height: 4em;
    display:flex;
    justify-content:space-around;
    align-items:center;
}

.filterContainer {
    width:20%;
    height:50px;
    border:2px solid var(--lapis);
    border-radius: 5px;
}

.filterSelection {
    width:100%;
    color:white;
    background-color:var(--lapis);
    text-align:center;
    text-align-last:center;
    font-size:100%;
    text-overflow: ellipsis;
    height:100%;
    border:0;
    cursor:pointer;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right .3em top 50%;
    background-size:1em auto;
    background-image: url("../../../../src/resources/icons/filter_arrow.png");
}

.time {
    display : flex;
    justify-content: center;
    align-items:center;
    width:60%;
}

.title {
    background-color: var(--lapis);
    color:#ffffff;
    font-size:1.1em;
    height:40px;
    padding-left: 1em;
    padding-right: 1em;
    width:65%;
    display:flex;
    justify-content:center;
    align-items:center;
}

.leftArrow {
    height: 0;
    width: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right:25px solid var(--lapis);
    margin-right: 2%;
    cursor:pointer;
}

.leftArrow:hover {
    border-right:25px solid var(--sunray);
}

.leftArrowDisabled {
    height: 0;
    width: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right:25px solid #d0d0d0;
    margin-right: 2%;
}

.rightArrow {
    height: 0;
    width: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 25px solid var(--lapis);
    margin-left:2%;
    cursor:pointer;
}

.rightArrow:hover {
    border-left: 25px solid var(--sunray);
}

.rightArrowDisabled {
    height: 0;
    width: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 25px solid #d0d0d0;
    margin-left:2%;
}

.zoomContainer {
    border: 2px solid var(--lapis);
    border-radius:10px;
    width: 15%;
    min-width:fit-content;
    display:flex;
    justify-content:space-around;
    overflow:hidden;
}

.zoomEnabled {
    display:flex;
    justify-content:center;
    align-items:center;
    height: 50px;
    width:100%;
    background-color:var(--lapis);
    box-shadow:3px 3px 5px #151515 inset;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    transition:all .3s;
    color:white;
    user-select: none;
}

.zoomDisabled {
    display:flex;
    justify-content:center;
    align-items:center;
    height: 50px;
    width:100%;
    background-color: #a6a6a6;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    transition:all .3s;
    cursor:pointer;
}

.zoomDisabled:hover {
    background-color: var(--sunray);
    color: white;
}

.loading {
    height: 25em;
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    background-color: #d0d0d0;
}

.hours {
    height: 20em;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    padding-top: 4em;
}

.hours p {
    margin: 0;
}
.day{
    width:100%;
}

.week{
    display:flex;
    height:100%;
}