.container{
    width:12em;
    height:fit-content;
    min-height:9em;
    text-align:center;
    color: #e3e3e3;
}

.icon{
    height:2em;
    width:2em;
    margin-top:1em;
}

.title{
    margin-top: .5em;
}

.value{
    margin-top:0;
    font-size:1.2em;
}