.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 10vh;
}

.footer{
  height: 50px;
  color: #fff;
  background-color: #3f51b5
}


* {
  box-sizing: border-box;
}

.grid-container {
  border: solid 4px #000;
  display: grid;
  grid-template-columns: 6em 6em 6em 6em;

}

/***********************************/
  /************* Dnd ***********/
/***********************************/


@media screen and (min-width:68px) {
  .tasks {
    display: flex;
    justify-content: space-between;
    width: 900px;
  }
}
.tasks__column {
  margin:0 1rem;
  flex:1;
}

.tasks__list {
  display: flex;
  flex-direction: column;
  min-height: 50px;
  border:1px solid #DDD;
  border-radius: 5px;;
  padding:.5rem 1rem;
  background-color: chartreuse;
}
.task__item {
  border: 3px solid #DDD;
  background-color: blueviolet;
  padding: .5rem;
  margin: .3rem 0;

}