.container {
    display:flex;
    flex-direction:column;
    user-select:none;

    width: 80%;
}

.icon {
    height: 1.2em;
    width: auto;
    float:left;
    margin-left:5px;
    margin-right:5px;
}

.warning {
    margin-bottom:3px;
    margin-top:0;
    padding:2px;
    color: #c7383b;
    background-color: #ea9494;
    border-radius: 5px;
    width: 100%;
}

.validation {
    margin-bottom:3px;
    margin-top:0;
    padding:2px;
    color: #1caa35;
    background-color: #91ce9a;
    border-radius: 5px;
}

.validField {
    font-size:1.2em;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 3px;
    border: 1px solid #1caa35;
    background-color: #89cb93;
}

.invalidField {
    font-size:1.2em;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid red;
    padding-left: 3px;
}

.buttonContainer {
    display : flex;
    justify-content: space-around;
}

.editButton {
    background-color:var(--lapis);
    color:#ffffff;
    min-width:7em;
    text-align: center;
    margin-top:1em;
    padding: 8px 5px 10px;
    font-size: 90%;
    display:inline-block;
    margin-right:5%;
    user-select: none;
    cursor:pointer;
}

.editButtonDisabled{
    background-color:#909090;
    color:#3e3e3e;
    min-width:7em;
    text-align: center;
    margin-top:1em;
    padding: 8px 5px 10px;
    font-size: 90%;
    display:inline-block;
    margin-right:5%;
    user-select: none;
}

.cancelButton {
    background-color:var(--brick);
    color:#ffffff;
    min-width:7em;
    text-align: center;
    margin-top:1em;
    padding: 8px 5px 10px;
    font-size: 90%;
    display:inline-block;
    margin-right:5%;
    cursor:pointer;
    user-select: none;
}