.container{
    background-color: #ffffff;
    border:2px solid var(--lapis);
    color: var(--lapis);
    padding-bottom: .5em;
    padding-left: 1em;
    padding-right: 1em;
    width:90em;
    transition:all .5s;
}

.containerExtended {
    background-color: #ffffff;
    border:2px solid var(--lapis);
    color: var(--lapis);
    padding-bottom: .5em;
    padding-left: 1em;
    padding-right: 1em;
    width:90%;
    transition:all .5s;
    margin-left: auto;
    margin-right: auto;
}

.header{
    align-items:center;
    display:flex;
    justify-content:center;
}

.arrowLeft {
    height: 0;
    width: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid var(--lapis);
    margin-right: 1em;
    cursor:pointer;
}

.arrowLeft:hover {
    border-right: 15px solid var(--sunray);
}

.arrowLeftDisabled {
    height: 0;
    width: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #e3e3e3;
    margin-right: 1em;
}

.arrowRight {
    height: 0;
    width: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid var(--lapis);
    margin-left: 1em;
    cursor:pointer;
}

.arrowRight:hover {
    border-left: 15px solid var(--sunray);
}

.arrowRightDisabled {
    height: 0;
    width: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #e3e3e3;
    margin-left: 1em;
}

.planningContainer{
    overflow-x:auto;
    overflow-y:auto;
    display:flex;
    height:40em;
}

.planningContainerExtended{
    overflow-x:auto;
    overflow-y:auto;
    display:flex;
    height:42.7em;
}

.hours{
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    padding-right:1em;
}

.secondaryContainer{
    display:flex;
    height:90%;
    width: 100%;
}

.planingUserContainer{
    display:flex;
    height:90%;
    width: 70%;
    margin-left: 5%;
}


.planning{
    display:block;
    background-color: #E4E4E4;
    width:100%;
}

.name{
    text-align:center;
    background-color:#ffffff;
    height:1.5em;
}




.JourneyUserContainer{
    background-color: #76baff;
    width:55%;
    flex-direction: column;
    flex-wrap: wrap;

}
