.container{
    width:100%;
    margin-top: auto;

}

.widgetsContainer{
    margin-top:auto;
    margin-right:auto;
    margin-left:auto;
    display:flex;
    justify-content:space-between;
    width:90%;
}