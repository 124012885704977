.container{
  overflow: auto;
}

.profileContainer{
    margin-top:2em;
    overflow: auto;
    height: 90%;
}

.infosContainer{
    display:flex;
    justify-content:space-around;
}

.image{
    height:8em;
    width:8em;
    background-position-x:center;
    background-position-y: center;
    background-size: cover;
    margin-right:auto;
    margin-left:auto;
    border:2px solid var(--seaweed);
    box-shadow: .5em .5em var(--sunray);
}
.name {
    font-size: 1.5em;
    font-weight: bold;

    color: #003D5B;
}
.name b {
    color: var(--seaweed);
}
.description{
    font-size:80%;
}

.section{
    font-size: 16px;
    padding: 5px;

}

.title {
 font-weight: bold;
 font-size: 16px;
 color: #003D5B;

}