.container{
    width: 100%;
    height:10em;
    background-color:#bababa;
    cursor: pointer;
}

.container:hover{
  background : linear-gradient(35deg, #bababa 10%,
    #40606f 10%, #40606f 20%,
    #bababa 20%, #bababa 30%,
    #40606f 30%, #40606f 40%,
    #bababa 40%, #bababa 50%,
    #40606f 50%, #40606f 60%,
    #bababa 60%, #bababa 70%,
    #40606f 70%, #40606f 80%,
    #bababa 80%, #bababa 90%,
    #40606f 90%);
}

.containerDisabled{
   width: 100%;
   height:10em;
   background-color:#808080;
}

.containerClicked{
   width: 100%;
   height:10em;
   background : linear-gradient(35deg, #bababa 10%,
   var(--indigo) 10%, var(--indigo) 20%,
   #bababa 20%, #bababa 30%,
   var(--indigo) 30%, var(--indigo) 40%,
   #bababa 40%, #bababa 50%,
   var(--indigo) 50%, var(--indigo) 60%,
   #bababa 60%, #bababa 70%,
   var(--indigo) 70%, var(--indigo) 80%,
   #bababa 80%, #bababa 90%,
   var(--indigo) 90%);

}
/*
.container{
    background : linear-gradient(35deg, #bababa 10%,
    #40606f 10%, #40606f 20%,
    #bababa 20%, #bababa 30%,
    #40606f 30%, #40606f 40%,
    #bababa 40%, #bababa 50%,
    #40606f 50%, #40606f 60%,
    #bababa 60%, #bababa 70%,
    #40606f 70%, #40606f 80%,
    #bababa 80%, #bababa 90%,
    #40606f 90%);

}*/


.containerClid{
    background :red;


}