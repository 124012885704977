.container{
    color: #ffffff;
    overflow:hidden;
    transition: all .5s;
    user-select:none;
    width:90%;
    text-overflow: ellipsis;
    text-align: left;

}

.container:hover .image {
    width:10em;
}

.containerMinimized{
    color: #ffffff;
    overflow:auto;
    transition: all .3s;
    user-select:none;
    width: 100%;
    min-width:6em;
    text-align: left;

}

.header{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    cursor:pointer;
    border-bottom: #000000;
}

.headerClicked{
    width:100%;
    display:flex;
    flex-direction:column-reverse;
    overflow:hidden;
}

.titleContainer{
    padding-left: 1em;
    float: left;
    overflow:hidden;
    width:60%;
    margin-top:auto;
    margin-bottom:auto;
    color: white;
    font-size: small;
}

.title{
    margin-top:10px;
    margin-bottom:4px;
    color: white;
    font-size: medium;
    text-align:center;

}

.titleMinimized{
    overflow:hidden;
    width:100%;
    margin-top:20px;
    margin-bottom:auto;
    text-align:center;
}

/*.place{
    margin-bottom: auto;
    margin-top: auto;
    color: black;
    font-size: medium;
    width:100%;
    text-align:center;
}*/

.place {

    margin-top: 20px;
    color: black;
    font-size: medium;
    width: 200px;
    text-align: center;
    height: auto;
    font-weight: bold;
}

.image {
    background-position-x:center;
    background-position-y:center;
    background-size: cover;
    transition:all .5s;
    width:6em;
}

.imageMinimized {
    width:0;
    height: 0;
    transition: all .5s;
}

.imageClicked{
    background-position-x:center;
    background-position-y: center;
    background-size: cover;
    align-self: flex-end;
    height: 6em;
    transition:all .5s;
    width:22em;
}

.gradient{
    min-height: 100%;
    width: 100%;
}

.mountContent{
    max-height: 0;
    margin-top: -1em;
    padding-left:1em;
    transition:all .5s;
}

.renderedContent{
    margin-top: 0;
    max-height: 15em;
    padding-bottom:.5em;
    padding-left:1em;
    transition:all .5s;
}


.insdispSection{
    background : linear-gradient(35deg, #bababa 10%,
    #40606f 10%, #40606f 20%,
    #bababa 20%, #bababa 30%,
    #40606f 30%, #40606f 40%,
    #bababa 40%, #bababa 50%,
    #40606f 50%, #40606f 60%,
    #bababa 60%, #bababa 70%,
    #40606f 70%, #40606f 80%,
    #bababa 80%, #bababa 90%,
    #40606f 90%);
    color: #000000;
    margin-top: 0;
    max-height: 50em;
    padding-bottom:.5em;
    padding-left:1em;
    transition:all .5s;
}



.insdispSectionIndisp{
    background : linear-gradient(35deg, #40606f 100%,   #40606f 70%, #40606f 80%);
    color: white;
    width: 100%;
    height: 100%;

}

.titleinsdispSectionIndisp{
    margin-top:auto;
    margin-bottom:4px;
    text-shadow: 4px 3px 5px black;
    height: 250px;
    height: 100px;

}