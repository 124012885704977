.container {
    width:100%;
    min-width: 10px;
    height: 6em;
    border: 1px solid var(--indigo);
    color: var(--lapis);
    -ms-overflow-style: none;  /* IE and Edge */
    overflow-style: marquee-line;
}

.disabledContainer {
    width:100%;
    min-width: 50px;
    height: 6em;
    background-color: rgba(85, 122, 177, 0.4);
    border: 1px solid var(--indigo);
    color: var(--indigo);
}

.date {
    margin-top:1%;
    margin-bottom: 8px;
    margin-left:8%;
}

.sessionsContainer {
    height: 100%;
    width: 100%;
    overflow-y:auto;
    overflow-x: hidden;

}


.session {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: grey;
    color:white;
    width:90%;
    min-height : 1.5em;
    height:15%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:3px;
    font-size:calc(.7em + .09vw);
    padding-left:5px;
}



.monText {
    text-overflow: ellipsis;
    color:white;
    width:90%;
    min-height : 1.5em;
    height:15%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:3px;
    font-size:calc(1em + .09vw);
    padding-left:5px;
}






