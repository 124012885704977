.dashboardContainer{
    height:100%;
    margin-top:2em;
    padding-bottom:2em;
    display:flex;
    justify-content:space-around;
    overflow:hidden;
}

.widgetsContainer{
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    transition:all .5s;
    max-width: 15em;
}

.widgetsContainerHidden{
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    max-width: 0px;
    transition:all .5s;

}